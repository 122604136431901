.linkedin-icon,
.github2-icon {
  fill: #919191;
  margin-left: 4px;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
}

.medium-icon {
  background-color: #000;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  fill: #919191;
  margin-left: 4px;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    fill: #fff;
  }
}
.github2-icon:hover {
  fill: #fff;
}
.linkedin-icon:hover {
  fill: #0e577f;
}
