.about-me-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: rgb(47, 51, 69); */
  background-color: #03031d;
  padding: 40px;
  .card-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 60px;
    .subtitle {
      margin-top: 20px;
      margin-bottom: 48px;
      color: rgb(152, 182, 188);
      font-weight: 500;
    }
  }
  .tech-stack {
    width: 100%;
    border-bottom: 1px solid rgb(122, 125, 140);
    border-top: 1px solid rgb(122, 125, 140);
    padding: 0 50px;
    padding-bottom: 20px;
  }
}

.test {
  text-align: center;
  height: 70px;
  color: #d8b3b82c;
  font-size: 100px;
  line-height: 3px;
}

@media only screen and (max-width: 1100px) {
  .test {
    font-size: 70px;
  }
}
@media only screen and (max-width: 768px) {
  .about-me-container {
    padding: 40px;
    .card-list {
      padding: 30px;
      .subtitle {
        margin-top: 20px;
        margin-bottom: 28px;
        font-size: 10px;
      }
    }
    .tech-stack {
      width: 100%;
      border-bottom: 1px solid rgb(122, 125, 140);
      border-top: 1px solid rgb(122, 125, 140);
      padding: 12px;
      padding-bottom: 20px;
    }
  }

  .test {
    text-align: center;
    height: 50px;
    color: #d8b3b82c;
    font-size: 32px;
    line-height: 3px;
  }
}
