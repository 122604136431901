.about-me-article-container {
  display: flex;
  align-items: center;
  padding: 50px;

  .memoji {
    width: 250px;
    background-color: rgb(61, 65, 84);
    border-radius: 16px;
  }
  .article {
    display: flex;
    flex-direction: column;
    padding: 30px;
    padding-right: 15px;
    color: rgb(122, 125, 140);
    color: #cacaca;
    font-size: 16px;
    .intro {
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 10px;
      color: #d8b3b8;
    }
    .information-container {
      display: flex;
      justify-content: center;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .about-me-article-container {
    .memoji {
      width: 200px;
    }
  }
}
@media only screen and (max-width: 1000px) {
  .about-me-article-container {
    .memoji {
      width: 150px;
    }
  }
}
@media only screen and (max-width: 900px) {
  .about-me-article-container {
    .memoji {
      width: 120px;
    }
  }
}
@media only screen and (max-width: 768px) {
  .about-me-article-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;

    .memoji {
      width: 120px;
    }
    .article {
      padding: 0;
      padding-top: 12px;
      color: rgb(122, 125, 140);
      color: #cacaca;
      font-size: 12px;
      .intro {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
        color: #d8b3b8;
      }
      .information-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}
