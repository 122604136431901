.information-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 36px;
  width: 25%;
  margin-right: 30px;
  background-color: rgb(16, 26, 46);
  border-radius: 16px;
  .img-wrapper {
    padding: 48px 0;
    .info-image {
      width: 80px;
      height: 80px;
      border-radius: 8px;
      margin-bottom: 16px;
      background-color: #f7df1e;
    }
  }
  .info-container {
    background-color: rgb(24, 36, 61);
    border-radius: 16px;
    padding: 16px;
    .title {
      font-weight: 550;
      font-size: 19px;
      padding-bottom: 12px;
      color: #d8b3b8;
    }
    .information {
      flex: 1;
      text-align: left;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .information-wrapper {
    width: 35%;
  }
}
@media only screen and (max-width: 1000px) {
  .information-wrapper {
    width: 200px;
  }
}
@media only screen and (max-width: 900px) {
  .information-wrapper {
    width: 150px;
  }
}
@media only screen and (max-width: 768px) {
  .information-wrapper {
    width: 100%;
    margin-right: 0;
    .img-wrapper {
      padding: 24px 0;
      .info-image {
        width: 60px;
        height: 60px;
        border-radius: 8px;
        margin-bottom: 16px;
        background-color: #f7df1e;
      }
    }
    .info-container {
      padding: 12px;
      .title {
        font-weight: 550;
        font-size: 16px;
        padding-bottom: 12px;
        color: #d8b3b8;
      }
      .information {
        text-align: left;
      }
    }
  }
}
