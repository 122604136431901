.about-me-card-container {
  display: flex;
  justify-content: space-between;
  width: 70%;
  border-radius: 8px;
  margin-bottom: 78px;
  &.odd {
    flex-direction: row-reverse;
  }
  &.even {
    flex-direction: row;
  }
  .info-container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */

    .title {
      align-self: flex-start;
      color: #af747c;
      padding: 50px 100px;
      font-size: 26px;
      text-align: center;
      font-weight: 500;
    }
    .info {
      text-align: left;
      padding: 0 15%;
      font-size: 16px;
      color: #fff;
    }
  }
  .card-img {
    width: 420px;
    height: 420px;
    width: 41%;
    height: 41%;
    border-radius: 8px;
  }
}

@media only screen and (max-width: 1500px) {
  .about-me-card-container {
    .info-container {
      .title {
        font-size: 20px;
      }
      .info {
        text-align: left;
        font-size: 14px;
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .about-me-card-container {
    .info-container {
      .title {
        font-size: 16px;
      }
      .info {
        text-align: left;
        font-size: 12px;
      }
    }
  }
}
@media only screen and (max-width: 1000px) {
  .about-me-card-container {
    .info-container {
      .title {
        font-size: 14px;
      }
      .info {
        text-align: left;
        font-size: 10px;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .about-me-card-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 78px;
    &.odd {
      flex-direction: column;
    }
    &.even {
      flex-direction: column;
    }
    .info-container {
      display: flex;
      flex-direction: column;
      /* align-items: center; */

      .title {
        align-self: flex-start;
        color: #af747c;
        padding: 24px 50px;
        font-size: 16px;
      }
      .info {
        text-align: left;
        padding: 0;
        padding-left: 30px;
        font-size: 12px;
      }
    }
    .card-img {
      width: 160px;
      height: 160px;
    }
  }
}
