.loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #03031d;
  .logo {
    width: 12rem;
    border-radius: 16px;
    animation: spin 2s linear infinite;
  }
}
.small-loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;
  .logo {
    width: 6rem;
    border-radius: 16px;
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 768px) {
  .loading-container {
    .logo {
      width: 10rem;
    }
  }
  .small-loading-container {
    .logo {
      width: 3rem;
    }
  }
}
