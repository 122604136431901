.project-container {
  display: flex;
  flex-direction: column;
  padding: 0 10rem;
  margin-top: 16px;
  border-bottom: 1px solid #d8b3b8;

  .productContainer {
    display: flex;
    align-items: center;
  }

  .previewContainer {
    display: flex;
    justify-content: center;
  }
  .title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title-icon {
      width: 200px;
      height: 64px;
    }
  }
  .app-icon {
    width: 128px;
    height: 128px;
    margin-top: 30px;
    border-radius: 16px;
    margin-bottom: 20px;
  }

  .app-image {
    width: 50rem;
    height: 28.8rem;
    border-radius: 10px;
    margin: 50px;
  }

  .app-image2 {
    width: 42rem;
    height: 24.3rem;
    border-radius: 10px;
  }

  .storeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    .github-icon {
      margin-top: 12px;
      width: 96px;
      height: 96px;
      fill: #03031d;
      cursor: pointer;
      &:hover {
        transform: scale(1.03);
        fill: #0f0f3d;
      }
    }
  }

  .details-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    background-color: #03031d;
    color: #d8b3b8;
    border: 1px solid #03031d;
    border-radius: 8px;
    padding: 8px 32px;
    margin-left: 30px;
    cursor: pointer;
    &:hover {
      background-color: #0f0f3d;
      border: 1px solid #d8b3b8;
    }
    .details-icon {
      margin-right: 12px;
      width: 32px;
      height: 32px;
    }
  }

  .infoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem;
    padding-top: 0;
  }

  .imac {
    width: 20rem;
  }

  .intro {
    font-size: 20px;
    padding-top: 50px;
    color: #cccccc;
    text-align: center;
  }
  .info {
    font-size: 20px;
    padding-top: 50px;
    color: #cccccc;
  }
}

@media only screen and (max-width: 768px) {
  .project-container {
    padding: 0 2rem;
    margin-top: 16px;

    .app-icon {
      width: 64px;
      height: 64px;
      margin-top: 10px;
      border-radius: 16px;
    }

    .imageContainer {
      display: flex;
      flex-direction: column;
      margin-top: 12px;
      align-items: center;
    }

    .app-image {
      width: 17rem;
      height: 9.6rem;
      border-radius: 10px;
      /* margin: 50px; */
      margin-right: 6rem;
    }

    .app-image2 {
      width: 14rem;
      height: 8.1rem;
      border-radius: 10px;
      margin-left: 2rem;
    }

    .storeContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 30px;
      .github-icon {
        width: 64px;
        height: 64px;
        fill: #03031d;
        cursor: pointer;
        &:hover {
          transform: scale(1.03);
          fill: #0f0f3d;
        }
      }
    }

    .details-button {
      padding: 8px 20px;
      cursor: pointer;
      font-size: 12px;
      .details-icon {
        margin-right: 12px;
        width: 24px;
        height: 24px;
      }
    }
    .title-container {
      .title-icon {
        margin-top: 20px;
        width: 100px;
        height: 1px;
      }
    }

    .infoContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      padding-bottom: 32px;
    }
    .previewContainer {
      display: block;
      justify-content: center;
    }

    .imac {
      width: 20rem;
    }

    .intro {
      font-size: 15px;
      padding-top: 20px;
      text-align: center;
    }
    .info {
      font-size: 14px;
      padding-top: 30px;
    }
  }
}
