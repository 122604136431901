.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(3, 3, 29);
  padding: 50px;
  padding-bottom: 16px;
  width: 100%;

  color: #fff;

  .logo {
    width: 100px;
    border-radius: 16px;
  }

  .location {
    font-weight: 500;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 20px;
  }

  .social {
    display: flex;
    align-items: center;
  }
  .author-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .avatar {
      width: 30px;
    }
    .author {
      font-weight: 500;
      font-size: 16px;
      margin-top: 10px;
    }
  }
}
.contact-button {
  align-self: flex-end;
  margin-bottom: 110px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 19px;
  letter-spacing: 8px;
  font-weight: lighter;
  width: 20rem;
  height: 5rem;
  border-radius: 40px;
  background-color: inherit;
  border: 1px solid #2e5432;
  color: #6a456c;
  cursor: pointer;
  &:hover {
    background-color: rgba(46, 84, 50, 0.4);
    color: #fff;
  }
  .icon {
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .footer-container {
    padding: 40px;
    padding-bottom: 20px;
    .logo {
      margin-top: 80px;
      width: 80px;
    }
    .contact-button {
      margin-bottom: 200px;
      margin-right: 30px;
      font-size: 16px;
      letter-spacing: 6px;
      width: 15rem;
      height: 4rem;
      padding: 10px;

      .icon {
        width: 30px;
        height: 30px;
      }
    }
  }
}
