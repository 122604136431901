.navigation-container {
  position: sticky;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 10px;
  /* background-color: rgba(40, 44, 60, 0.625); */
  background-color: rgb(3, 3, 29);
  background-color: #03031d5d;
  height: 100%;
  padding: 10px 90px;
  border: 1px solid rgb(130, 145, 160);
  border-top: none;

  .link {
    text-decoration: none;
    font-size: 17px;
    color: rgb(130, 145, 160);
    color: #ffff;
    margin-right: 30px;
    padding: 15px 25px;
    cursor: pointer;
  }
  .link:hover {
    color: #ffff;
    background-color: rgb(15, 15, 65);
    border-radius: 16px;
  }
  .link:active {
    background-color: #d8b3b8;
  }

  .link-active {
    background-color: rgb(3, 3, 29);
  }

  .active {
    color: #ffff;
    background-color: rgb(15, 15, 65);
    border-radius: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .navigation-container {
    height: 100%;
    padding: 10px 9px;

    .link {
      font-size: 10px;
      color: rgb(130, 145, 160);
      color: #ffff;
      margin-right: 3px;
      padding: 4px 8px;
      cursor: pointer;
    }
  }
}
