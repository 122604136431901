.tech-stack-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  .about-me-card-container {
    display: flex;
    .title {
    }
    .card-img {
      width: 30px;
    }
  }
  .tech-stack-list {
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tech-stack-icon {
      width: 100px;
      height: 100px;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
    }
    .emotion {
      width: 70px;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
    }
    .styled {
      width: 90px;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .tech-stack-container {
    .tech-stack-list {
      .tech-stack-icon {
        width: 60px;
        height: 60px;
      }
      .emotion {
        width: 50px;
      }
      .styled {
        width: 58px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .tech-stack-container {
    .tech-stack-list {
      width: 100%;
      margin-top: 10px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      row-gap: 30px;
      column-gap: 50px;
      padding: 0;
      margin-right: 0;
      .tech-stack-icon {
        width: 60px;
        height: 60px;
      }
      .emotion {
        width: 40px;
      }
      .styled {
        width: 48px;
      }
    }
  }
}
