.project-card-list-container {
  margin-top: 50px;
  .project-card-list {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 64px;
    padding-top: 0;
    .project-list-macbook {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 10px;
      column-gap: 50px;
      padding: 20px;
      margin-right: 48px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .project-card-list-container {
    margin-top: 25px;
    .project-card-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 32px;
      padding-top: 0;
      .project-list-macbook {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 8px;
        column-gap: 24px;
        padding: 12px;
        padding-top: 20px;
        margin-right: 28px;
      }
    }
  }
}
