.card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .project-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      .project-image {
        transform: scale(1.08);
      }
      .project-image-iphone {
        transform: scale(1.05);
      }
      .project-title {
        color: #dac5c8;
        text-decoration: underline;
      }
    }

    .project-title {
      color: #9e797e;
      font-weight: 500;
      margin-bottom: 20px;
    }
    .project-image {
      border-radius: 16px;
      width: 20rem;
    }
    .project-image-iphone {
      width: 13rem;
      border-radius: 32px;
    }
  }

  .card-github-icon {
    margin-top: 8px;
    fill: #03031d;
    cursor: pointer;
    &:hover {
      transform: scale(1.03);
      fill: #0f0f3d;
      fill: #fff;
    }
  }
}

@media only screen and (max-width: 768px) {
  .card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .project-card-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .project-title {
        margin-bottom: 20px;
        font-size: 14px;
      }
      .project-image {
        width: 10rem;
      }
      .project-image-iphone {
        width: 8rem;
        border-radius: 32px;
      }
    }

    .card-github-icon {
      margin-top: 8px;
      fill: #03031d;
      cursor: pointer;
      &:hover {
        transform: scale(1.03);
        fill: #0f0f3d;
        fill: #fff;
      }
    }
  }
}
