.dark-mode-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  overflow-y: auto;
}

.welcome-page {
  display: flex;
  flex-direction: column;
}
