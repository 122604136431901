.projects-page-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin-top: 40px;
  .subtitle {
    font-size: 20px;
    color: #a8a29b;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .project-card-list {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 768px) {
  .projects-page-container {
    .subtitle {
      font-size: 13px;
      margin-bottom: 10px;
    }
  }
}
