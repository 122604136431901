.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #282c3c;
  width: 100%;
  height: 86vh;
  background-image: url("../../assets/images/background/background2.svg");
  /* background-image: url("../../assets/images/background/background2.svg"); */
  background-size: cover;
  background-position: center center;
  height: 100vh;

  /* animation: diamond-in-hesitate 3s; */
  .image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .macintosh {
      width: 60%;
      border-radius: 8px;
      margin-top: 100px;
    }
    .js {
      width: 50px;
      border-radius: 8px;
    }
  }

  .info {
    text-align: center;
    z-index: 2;
    padding: 20px;
    width: 40%;
    .typewriter {
      width: 100%;
      font-size: 30px;
      color: #d8b3b8;
    }

    .title {
      justify-content: center;
      color: #fff;
      font-size: 36px;
      font-weight: 300;
      margin-bottom: 30px;
      margin-top: 30px;
      .first-letters {
        font-size: 40px;
        font-weight: 600;
        /* -webkit-text-stroke: 4px #d6f4e5; */
        /* font-family: "For"; */
      }
    }
    .about-me {
      color: #fff;
      font-size: 16px;
    }
  }
}

.lottie-wrapper {
  background-color: rgb(36, 45, 83);
  background-color: rgb(40, 44, 60);
}

.curly-brace-icon,
.virus-icon,
.engineer-icon,
.cloud-icon,
.lego-icon,
.responsive-icon,
.planet-icon,
.maximize-icon {
  width: 72px;
  height: 72px;
  position: absolute;
  fill: #d8b3b8;
  /* background-color: #03031d; */
  padding: 10px;
  border-radius: 8px;
  background: #0d0d35;
  box-shadow:
    inset -13px -13px 14px #050515,
    inset 13px 13px 14px #151555;
}

.curly-brace-icon {
  right: 8%;
  top: 10%;
  animation: rotateAnimationRight 50s infinite linear;
}

.virus-icon {
  left: 8%;
  bottom: 10%;
  animation: rotateAnimationRight 50s infinite linear;
}
.engineer-icon {
  right: 8%;
  bottom: 20%;
  animation: rotateAnimationRight 50s infinite linear;
}
.cloud-icon {
  left: 8%;
  top: 10%;
  animation: rotateAnimationLeft 50s infinite linear;
}
.lego-icon {
  left: 16%;
  top: 37%;
  animation: rotateAnimationRight 50s infinite linear;
}
.planet-icon {
  right: 20%;
  top: 30%;
  animation: rotateAnimationLeft 50s infinite linear;
}
.responsive-icon {
  left: 25%;
  bottom: 30%;
  animation: rotateAnimationLeft 50s infinite linear;
}
.maximize-icon {
  right: 26%;
  bottom: 11%;
  animation: rotateAnimationLeft 50s infinite linear;
}

@keyframes rotateAnimationRight {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotateAnimationLeft {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes diamond-in-hesitate {
  0% {
    clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
  }
  50% {
    clip-path: polygon(45% 50%, 50% 25%, 55% 50%, 50% 75%);
  }
  100% {
    clip-path: polygon(-50% 50%, 50% -50%, 150% 50%, 50% 150%);
  }
}

[transition-style="in:diamond:hesitate"] {
  animation: 1.5s cubic-bezier(0.25, 1, 0.3, 1) diamond-in-hesitate both;
}

@media only screen and (max-width: 768px) {
  .welcome-container {
    .welcome {
      text-align: center;
      margin-bottom: 30px;
    }
    .image-container {
      .macintosh {
        width: 60%;
        margin-top: 32px;
      }
    }
    .info {
      padding: 20px;
      width: 100%;
      .typewriter {
        width: 100%;
        font-size: 30px;
        color: #d8b3b8;
      }
      .title {
        color: #fff;
        font-size: 24px;
        margin-bottom: 30px;
        margin-top: 10px;
        text-align: center;
        .first-letters {
          /* font-size: 30px; */
          font-size: 25px;
        }
      }
      .about-me {
        font-size: 13px;
      }
    }
  }

  .curly-brace-icon,
  .virus-icon,
  .engineer-icon,
  .cloud-icon,
  .lego-icon,
  .responsive-icon,
  .planet-icon,
  .maximize-icon {
    /* display: none; */
    width: 32px;
    height: 32px;
    position: absolute;
    fill: #d8b3b8;
    padding: 5px;
  }
  .engineer-icon {
    right: 8%;
    bottom: 35%;
    width: 40px;
    height: 40px;
  }

  .planet-icon {
    right: 4%;
  }
  .lego-icon {
    left: 6%;
  }
  .responsive-icon {
    left: 13%;
  }
  .maximize-icon {
    right: 16%;
    bottom: 16%;
    animation: rotateAnimationLeft 50s infinite linear;
  }

  .virus-icon {
    left: 47%;
    bottom: 6%;
  }
}
