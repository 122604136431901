.logo-container {
  width: 100%;
  padding: 0 30px;
  margin-top: 4px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: rgba(40, 44, 60, 0.625); */
  background-color: #03031d5d;
  top: 0;
  left: 0;
  right: 0;
  z-index: 22;
}
.logo {
  width: 50px;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 1s;
}
.logo:hover {
  animation: rotateRight 1s forwards;
}
.logo:not(:hover) {
  animation: rotateLeft 1s forwards;
}
.button-wrapper {
  display: flex;
  align-items: center;
  padding: 0 10px;
  .language-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .language-button {
      width: 20px;
      margin-right: 5px;
      cursor: pointer;
    }
    .language-button-active {
      margin-right: 5px;
      width: 25px;
      transform: scale(1.05);
      cursor: pointer;
    }
    .language-button:hover {
      transform: scale(1.1);
    }
  }
}

@keyframes rotateRight {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotateLeft {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

@media only screen and (max-width: 768px) {
  .logo-container {
    padding: 0 10px;
  }
  .logo {
    width: 32px;
  }
  .button-wrapper {
    padding: 0 10px;
    .language-button-container {
      .language-button {
        width: 13px;
        margin-right: 5px;
      }
      .language-button-active {
        margin-right: 5px;
        width: 15px;
      }
    }
  }
}
